<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">
               {{ $t('elearning_tim.other_allowance') }} {{ $t('globalTrans.insert') }}
            </h4>
          </template>
          <template v-slot:headerAction>
            <router-link to="other-allowance-list" :class="'btn btn-success text-light mr-1'">{{ $t('elearning_tim.other_allowance') }} {{ $t('globalTrans.list') }}</router-link>
          </template>
          <template v-slot:body>
            <b-row>
              <b-col lg="12" sm="12">
                <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                  <b-form id="form" @submit.prevent="handleSubmit(createData)" @reset.prevent="reset" >
                    <b-row>
                    </b-row>
                    <b-overlay :show="load">
                      <b-row>
                        <b-col lg="6" sm="6">
                          <ValidationProvider name="Fiscal Year"  vid="fiscal_year_id" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="fiscal_year_id"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('elearning_config.fiscal_year')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-select
                                  plain
                                  v-model="formData.fiscal_year_id"
                                  :options="fiscalYearList"
                                  id="fiscal_year_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                              >
                                <template v-slot:first>
                                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                              </b-form-select>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="6">
                          <ValidationProvider  v-if="$store.state.Auth.activeRoleId === 1" name="Organization" vid="org_id" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="org_id"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{ $t('elearning_config.organization')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-select
                                  plain
                                  v-model="formData.org_id"
                                  :options="orgList"
                                  id="org_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                              >
                                <template v-slot:first>
                                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                              </b-form-select>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="6">
                          <ValidationProvider name="Office Type" vid="office_type_id" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="office_type_id"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('elearning_config.office_type')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-select
                                  plain
                                  v-model="formData.office_type_id"
                                  :options="officeTypeList"
                                  id="office_type_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  :disabled="$store.state.Auth.activeRoleId === 1 ? false : true"
                              >
                                <template v-slot:first>
                                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                              </b-form-select>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="6">
                          <ValidationProvider name="Office" vid="office_id" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="office_id"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('globalTrans.office')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-select
                                  plain
                                  v-model="formData.office_id"
                                  :options="officeList"
                                  id="office_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  :disabled="$store.state.Auth.activeRoleId === 1 ? false : true"
                              >
                                <template v-slot:first>
                                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                              </b-form-select>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="6">
                          <ValidationProvider name="Training Type"  vid="training_type_id" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="training_type_id"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('elearning_config.training_type')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-select
                                plain
                                v-model="formData.training_type_id"
                                :options="trainingTypeList"
                                id="training_type_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="6">
                          <ValidationProvider name="Training Category" vid="training_category_id" rules="required|min_value:1">
                            <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="training_category_id"
                              slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('elearning_config.training_category')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-select
                                plain
                                v-model="formData.training_category_id"
                                :options="trainingCategoryList"
                                id="training_category_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="6">
                          <ValidationProvider name="Training Title" vid="training_title_id" rules="required|min_value:1">
                            <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="training_title_id"
                              slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('elearning_config.training_title')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-select
                                plain
                                v-model="formData.training_title_id"
                                :options="trainingTitleList"
                                id="training_title_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="6">
                          <ValidationProvider name="Circular Memo No" vid="circular_memo_no" rules="required">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="circular_memo_no"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('elearning_iabm.circular_memo_no')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-select
                                  plain
                                  v-model="formData.circular_memo_no"
                                  :options="circularList"
                                  id="circular_memo_no"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                  <b-form-select-option :value="0">{{ circularLoading ? 'Loading....' : $t('globalTrans.select') }}</b-form-select-option>
                                </template>
                              </b-form-select>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="6">
                          <ValidationProvider name="Other Trainee Type" vid="other_trainee_type_id" rules="required">
                            <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="batch_no"
                            >
                            <template v-slot:label>
                              {{$t('elearning_config.trainee_type')}} <span class="text-danger">*</span>
                            </template>
                              <b-form-select
                                plain
                                v-model="formData.other_trainee_type_id"
                                :options="traineeTypeList"
                                id="other_trainee_type_id"
                                >
                                <template v-slot:first>
                                     <b-form-select-option :value="0">{{ circularLoading ? 'Loading....' : $t('globalTrans.select') }}</b-form-select-option>
                                </template>
                              </b-form-select>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="6">
                          <ValidationProvider name="Honour Amount" vid="honour_amount">
                            <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="honour_amount"
                              slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('elearning_tim.other_allowance')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-input
                                v-model="formData.honour_amount"
                                :placeholder="$t('globalTrans.honour_amount')"
                                :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="6">
                          <ValidationProvider name="Date" vid="date">
                            <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="date"
                              slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('globalTrans.select_date')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-input
                                class="fromDate"
                                v-model="formData.payment_date"
                                :placeholder="$t('globalTrans.select_date')"
                                :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                      </b-row>
                    </b-overlay>
                    <b-row>
                      <div class="col-md-12">
                        <fieldset class="p-2 w-100">
                          <legend class="px-2 w-50 shadow-sm">{{$t('elearning_tim.other_allowance')}}</legend>
                            <b-overlay :show="loading">
                              <table class="table" style="width:100%;font-size: 12px">
                                <thead class="thead">
                                  <tr>
                                    <th class="text-center" style="width:7%">{{$t('globalTrans.sl_no')}}</th>
                                    <th style="width:12%">{{$t('globalTrans.name')}}</th>
                                    <th style="width:12%">{{$t('elearning_tim.profession')}}</th>
                                    <th style="width:7%">{{$t('globalTrans.number_of_days')}}</th>
                                    <th style="width:12%">{{$t('elearning_tim.other_allowance')}}</th>
                                    <th style="width:7%">{{$t('globalTrans.total_amount')}}</th>
                                    <th style="width:7%">{{$t('globalTrans.other_detuction')}}</th>
                                    <th style="width:10%">{{$t('globalTrans.net_amount')}}</th>
                                  </tr>
                                </thead>
                                <tr v-for="(train,index) in formData.payments" :key="train.id">
                                  <td class="text-center">{{$n(index+1)}}</td>
                                  <td>
                                    <div style="font-size: 14px">
                                      {{ ($i18n.locale==='bn') ? train.name_bn : train.name }}
                                    </div>
                                  </td>
                                  <td>
                                    <div style="font-size: 14px">
                                      {{ train.designation }}
                                    </div>
                                  </td>
                                  <td>
                                    <b-form-input
                                      rows="2"
                                      v-model="train.total_days"
                                      :placeholder="$t('elearning_tim.number_of_class')"
                                      @input="paymentAmountUpdate(train)"
                                    ></b-form-input>
                                    <small v-if="paymentErrors && paymentErrors['payments.' + index + '.total_days']" class="text-danger">
                                      {{ paymentErrors['payments.'+index+'.total_days'] }}
                                    </small>
                                  </td>
                                  <td>
                                    <b-form-input
                                      rows="2"
                                      v-model="train.honour_amount"
                                      :placeholder="$t('elearning_tim.other_allowance')"
                                      @input="paymentAmountUpdate(train)"
                                    ></b-form-input>
                                    <small v-if="paymentErrors && paymentErrors['payments.' + index + '.honour_amount']" class="text-danger">
                                      {{ paymentErrors['payments.'+index+'.honour_amount'] }}
                                    </small>
                                  </td>
                                  <td>{{train.total_days * train.honour_amount}}</td>
                                  <td>
                                    <b-form-input
                                      rows="2"
                                      v-model="train.other"
                                      :placeholder="$t('globalTrans.other_detuction')"
                                      @input="paymentAmountUpdate(train)"
                                    ></b-form-input>
                                    <small v-if="paymentErrors && paymentErrors['payments.' + index + '.other']" class="text-danger">
                                      {{ paymentErrors['payments.'+index+'.other'] }}
                                    </small>
                                  </td>
                                  <td>
                                    <b-form-input
                                      v-model="train.payment_amount"
                                      :placeholder="$t('globalTrans.payment_amount')"
                                      readonly
                                    ></b-form-input>
                                    <small v-if="paymentErrors && paymentErrors['payments.' + index + '.payment_amount']" class="text-danger">
                                      {{ paymentErrors['payments.'+index+'.payment_amount'] }}
                                    </small>
                                  </td>
                                </tr>
                                <template v-if="formData.payments.length === 0">
                                  <tr>
                                    <th colspan="10" class="text-center text-danger">{{$t('globalTrans.noDataFound')}}</th>
                                  </tr>
                                </template>
                              </table>
                            </b-overlay>
                        </fieldset>
                      </div>
                    </b-row>
                    <div class="row">
                        <div class="col-sm-3"></div>
                        <div class="col text-right">
                          <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                        </div>
                    </div>
                  </b-form>
                </ValidationObserver>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
      <!-- <pre>{{formData}}</pre> -->
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { billPaymentStore, billPaymentPaymentSearchOther, circularPublication, circularList } from '../../api/routes'
import flatpickr from 'flatpickr'
const today = new Date().toISOString().substr(0, 10)

export default {
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  created () {
    this.formData = Object.assign({}, this.formData, {
      fiscal_year_id: this.$store.state.TrainingElearning.currentFiscalYearId
    })
    if (this.$store.state.Auth.activeRoleId !== 1) {
      this.formData.org_id = this.$store.state.Auth.authUser.org_id
      this.formData.office_type_id = this.$store.state.Auth.authUser.office_type_id
      this.formData.office_id = this.$store.state.Auth.authUser.office_id
      this.officeTypeList = this.getOfficeTypeOfficerList(this.$store.state.Auth.authUser.office_type_id)
    }
    // this.getCircularList()
    if (this.$route.query.id) {
      this.getFormData()
    }
  },
  mounted () {
    core.index()
    flatpickr('.fromDate', {})
  },
  data () {
    return {
      loading: false,
      circularLoading: false,
      load: false,
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      formData: {
        payment_type: 'Trainee',
        fiscal_year_id: 0,
        office_type_id: 0,
        office_id: 0,
        org_id: 0,
        training_category_id: 0,
        training_type_id: 0,
        training_title_id: 0,
        other_trainee_type_id: 0,
        circular_memo_no: 0,
        batch_no: 0,
        vat_tax: 0,
        honour_amount: 0,
        training_status: 2,
        payments: []
      },
      officeTypeList: [],
      payment: [],
      officeList: [],
      courseModuleList: [],
      courseList: [],
      circularList: [],
      allBatchListData: [],
      trainingTypeList: [],
      batchList: [],
      required: true,
      trainingCategoryList: [],
      attachments_errors: [],
      trainingElearningServiceBaseUrl: trainingElearningServiceBaseUrl,
      trainerListData: [],
      trainingTitleList: [],
      traineeTypeList: [],
      otherTraineeTypeList: [],
      paymentErrors: []
    }
  },
  computed: {
    trainerList: function () {
      const listObject = this.trainerListData
      const tmpList = listObject.map((obj, index) => {
        if (this.$i18n.locale === 'bn') {
          return { value: obj.value, text: obj.text_en }
        } else {
          return { value: obj.value, text: obj.text_bn }
        }
      })
      return tmpList
    },
    venus: function () {
      return this.$store.state.TrainingElearning.commonObj.venus.filter(item => item.status === 1)
    },
    trainingQuarterSetupList: function () {
      return this.$store.state.TrainingElearning.commonObj.trainingQuarterSetupList.filter(item => item.status === 1)
    },
    paymentType: function () {
      return this.$store.state.TrainingElearning.commonObj.paymentType
    },
    orgList: function () {
      return this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
    },
    fiscalYearList: function () {
      return this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 0)
    }
  },
  watch: {
    'formData.circular_memo_no': function (newValue, oldValue) {
      if (newValue !== oldValue) {
        const allBatchListData = this.allBatchListData.find(allBatchListData => allBatchListData.circular_memo_no === newValue)
        if (typeof allBatchListData !== 'undefined') {
          this.formData.batch_no = allBatchListData.batch_no
        }
      }
      this.getOtherTraineeType()
    },
    'formData.honour_amount': function (newValue, oldVal) {
      if (newValue !== oldVal) {
        this.vatAddAll()
      }
    },
    'formData.vat_tax': function (newValue, oldVal) {
      if (newValue !== oldVal) {
        this.vatAddAll()
      }
    },
    'formData.org_id': function (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.trainingTypeList = this.getTypeList(newValue)
        if (this.$store.state.Auth.activeRoleId === 1) {
          this.officeTypeList = this.getOfficeTypeList(newValue)
        }
      } else {
        this.trainingTypeList = []
      }
    },
    'formData.office_type_id': function (newValue) {
      this.officeList = this.getOfficeList(newValue)
    },
    'formData.training_type_id': function (newValue) {
      this.trainingCategoryList = this.getCategoryList(newValue)
    },
    'formData.training_category_id': function (newValue) {
      this.trainingTitleList = this.getTrainingTitleList(newValue)
    },
    'formData.fiscal_year_id': function (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.getCircularList()
        // this.getSheduleList()
      }
    },
    'formData.training_title_id': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.getCircularList()
      }
    },
    'formData.other_trainee_type_id': function (newVal, oldVal) {
        this.getCircularMemoNo()
    }
  },
  methods: {
    async getOtherTraineeType () {
      this.circularLoading = true
      const serchData = {
        org_id: this.formData.org_id,
        fiscal_year_id: this.formData.fiscal_year_id,
        office_id: this.formData.office_id,
        office_type_id: this.formData.office_type_id,
        circular_memo_no: this.formData.circular_memo_no
      }
      const result = await RestApi.getData(trainingElearningServiceBaseUrl, '/iabm/circular-publications/other-trainee-type' + '/' + 'iab_circular_publications', serchData)
      if (!result.success) {
        this.otherTraineeTypeList = []
      } else {
        this.otherTraineeTypeList = result.otherTraineeTypeList
        this.getTraineeType()
      }
      this.circularLoading = false
    },
    getTraineeType () {
      const typeData = this.otherTraineeTypeList.map((obj, index) => {
        if (this.$i18n.locale === 'bn') {
          return { value: obj.id, text: obj.other_trainee_type_bn }
        } else {
          return { value: obj.id, text: obj.other_trainee_type }
        }
      })
      if (typeData) {
        this.traineeTypeList = typeData
      } else {
        this.traineeTypeList = []
      }
    },
    getTypeList (orgId) {
      if (this.formData.org_id) {
        const type = this.$store.state.TrainingElearning.commonObj.trainingTypeList.filter(item => item.status === 1)
        if (orgId) {
          return type.filter(item => item.org_id === parseInt(orgId))
        }
        return type
      }
    },
    getOfficeTypeOfficerList (officeTypeId) {
      const OfficeTypeList = this.$store.state.commonObj.officeTypeList.filter(item => item.status === 0)
      if (officeTypeId) {
        return OfficeTypeList.filter(item => item.value === officeTypeId)
      }
      return OfficeTypeList
    },
    async getCircularList () {
      if (this.formData.fiscal_year_id && this.formData.training_title_id) {
        this.circularLoading = true
        const serchData = {
          fiscal_year_id: this.formData.fiscal_year_id,
          training_type_id: this.formData.training_type_id,
          training_category_id: this.formData.training_category_id,
          training_title_id: this.formData.training_title_id,
          office_id: this.formData.office_id
        }
        const result = await RestApi.getData(trainingElearningServiceBaseUrl, circularList + '/' + 'iab_circular_publications', serchData)
        if (!result.success) {
          this.allBatchListData = []
        } else {
          this.allBatchListData = result.batchList
          this.circluarList()
        }
        this.circularLoading = false
      }
    },
    circluarList () {
      const listObject = this.allBatchListData.filter(allBatchListData => parseInt(allBatchListData.training_title_id) === parseInt(this.formData.training_title_id))
      const tmpList = listObject.map((obj, index) => {
        if (this.$i18n.locale === 'bn') {
          return { value: obj.circular_memo_no, text: obj.circular_memo_no, batch_no: obj.batch_no }
        } else {
          return { value: obj.circular_memo_no, text: obj.circular_memo_no, batch_no: obj.batch_no }
        }
      })
      this.circularList = tmpList
    },
    async getCircularMemoNo () {
      this.load = true
      const params = {
        circular_memo_no: this.formData.circular_memo_no,
        table: 'iab_circular_publications'
      }
      const result = await RestApi.getData(trainingElearningServiceBaseUrl, circularPublication, params)
      if (!result.success) {
        this.formData.fiscal_year_id = 0
        this.formData.org_id = 0
        this.formData.office_id = 0
        this.formData.office_type_id = 0
        this.formData.training_title_id = 0
        this.formData.training_type_id = 0
        this.formData.training_category_id = 0
        this.formData.honour_amount = 0
        this.formData.vat_tax = 0
        this.formData.payment_date = 0
      } else {
        this.lcErrorMsg = ''
        const trData = result.data
        this.formData.fiscal_year_id = trData.fiscal_year_id
        this.formData.org_id = trData.org_id
        this.formData.office_id = trData.office_id
        this.formData.office_type_id = trData.office_type_id
        this.formData.training_title_id = trData.training_title_id
        this.formData.training_type_id = trData.training_type_id
        this.formData.training_category_id = trData.training_category_id
        // this.formData.honour_amount = trData.honour_amount
        // this.formData.vat_tax = trData.vat_tax
        // this.formData.payment_date = trData.payment_date
        this.getTrainerSheduleList()
      }
      this.load = false
    },
    vatAddAll () {
      const payments = this.formData.payments.map(item => {
        const amountData = {}
        item.vat_tax = this.formData.vat_tax
        item.honour_amount = this.formData.honour_amount
        const paymentCalculate = this.paymentAmountUpdate(item)
        amountData.vat_tax_amount = paymentCalculate.vat_tax_amount
        amountData.payment_amount = paymentCalculate.payment_amount
        return Object.assign({}, item, amountData)
      })
      this.formData.payments = payments
    },
    paymentAmountUpdate (train) {
      const amount = parseFloat(train.honour_amount) * parseFloat(train.total_days)
      train.vat_tax_amount = this.calculatePercentage(amount, train.vat_tax)
      train.payment_amount = (parseFloat(amount) - parseFloat(train.vat_tax_amount) - parseFloat(train.other)) - parseFloat(train.detucton)
      return train
    },
    calculatePercentage (oldAvg, newAvg) {
      const totalPercentage = (parseFloat(oldAvg) * newAvg) / 100
      return totalPercentage
    },
    removeAttachment (index, data) {
      this.formData.module_attachments.splice(index, 1)
      if (data.pre_attachment) {
        this.formData.module_attachments_remove.push(data)
      }
    },
    getOfficeTypeList (orgId) {
      const OfficeTypeList = this.$store.state.commonObj.officeTypeList.filter(item => item.status === 0)
      if (orgId) {
        return OfficeTypeList.filter(item => item.org_id === orgId)
      }
      return OfficeTypeList
    },
    getOfficeList (officeTypeId) {
      const officeList = this.$store.state.commonObj.officeList.filter(item => item.status === 0)
      if (officeTypeId) {
        return officeList.filter(item => item.office_type_id === officeTypeId)
      }
      return officeList
    },
    getCategoryList (typeId) {
       const trainingCategoryList = this.$store.state.TrainingElearning.commonObj.trainingCategoryList.filter(item => item.status === 1)
       if (typeId) {
         return trainingCategoryList.filter(item => item.training_type_id === typeId)
       }
       return trainingCategoryList
    },
    getTrainingTitleList (categoryId) {
      const trainingTitleList = this.$store.state.TrainingElearning.commonObj.trainingTitleList.filter(item => item.status === 1)
      if (categoryId) {
        return trainingTitleList.filter(item => item.training_category_id === categoryId)
      }
      return trainingTitleList
    },
    async getTrainerSheduleList () {
      if (this.formData.training_title_id && this.formData.training_category_id && this.formData.training_type_id) {
        this.loading = true
        const data = this.formData
        data.payments = []
        const result = await RestApi.getData(trainingElearningServiceBaseUrl, billPaymentPaymentSearchOther, data)
        if (!result.success) {
          this.formData.payments = []
          this.loading = false
          this.required = true
        } else {
          const resultData = result.data
          this.payment = result.payment
          const payments = resultData.map(item => {
            const desigObj = this.$store.state.commonObj.designationList.find(designation => designation.value === parseInt(item.designation_id))
            const desigData = {}
            if (typeof desigObj !== 'undefined') {
              desigData.designation_name = desigObj.text_en
              desigData.designation_name_bn = desigObj.text_bn
            } else {
              desigData.designation_name = ''
              desigData.designation_name_bn = ''
            }
            const paymentObj = this.payment.find(payment => payment.training_application_id === parseInt(item.id) && payment.training_title_id === parseInt(item.training_title_id))
            const paymentData = {}
            paymentData.training_application_id = item.id
            if (typeof paymentObj !== 'undefined') {
              paymentData.id = paymentObj.id
              paymentData.total_days = paymentObj.total_days
              paymentData.honour_amount = paymentObj.honour_amount
              paymentData.vat_tax = paymentObj.vat_tax
              paymentData.vat_tax_amount = paymentObj.vat_tax_amount
              paymentData.other = paymentObj.other
              paymentData.detucton = paymentObj.detucton
              paymentData.payment_amount = paymentObj.payment_amount
              paymentData.payment_date = paymentObj.payment_date
              paymentData.comments = paymentObj.comments
              let disbledPayment = false
              if (paymentObj.payment_status === 2) {
                disbledPayment = false
              }
              paymentData.payment_disabled = disbledPayment
              this.required = false
            } else {
              paymentData.id = 0
              paymentData.total_days = 1
              paymentData.honour_amount = 0
              paymentData.vat_tax = 0
              paymentData.vat_tax_amount = 0
              paymentData.other = 0
              paymentData.detucton = 0
              paymentData.payment_amount = 0
              paymentData.comments = ''
              paymentData.payment_date = today
              paymentData.payment_disabled = false
            }
            return Object.assign({}, item, desigData, paymentData)
          })
          this.formData.payments = payments
          // this.vatAddAll()
          this.loading = false
        }
      }
    },
    async createData () {
      this.loading = true
      let result = null
      result = await RestApi.postData(trainingElearningServiceBaseUrl, billPaymentStore, this.formData)
      this.loading = false
      if (result.success) {
        this.$toast.success({
            title: 'Success',
            message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
            color: '#D6E09B'
        })
        this.getTrainerSheduleList()
        this.$router.push('/training-e-learning-service/tim/other-allowance-list')
      } else {
        this.paymentErrors = result.errors
        this.$refs.form.setErrors(result.errors)
        // this.$refs.form.setErrors(result.errors)
      }
  }
  }
}
</script>
